export const INTERVIEW_STATUSES = [12, 13, 27, 28, 29];
export const INTERVIEW_REQUIRED_STATUSES = [12, 13];
export const QUESTIONNAIRE_PROCESSING_STAGE = [8];
export const SELECTION_STAGE = [6, 12, 13, 25];
export const EMPLOYMENT_STAGE = [22, 27, 28, 29, 30, 35];
export const REGISTRATION_STATUSES = [28, 29];
export const CLARIFICATION_STATUSES = [2, 6, 8, 22];

export const NOT_FOUND_TEXT = "Данная страница не существует или у вас недостаточно прав."

export const TIMES = [
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
]
